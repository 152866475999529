import React from "react";
import SEO from "../components/seo";
import Header from "../components/header";
import Section from "../components/section";
import ServiceBar from "../components/serviceBar";
import Checkout from "../components/checkout";
import Layout from "../components/layout";
import H1 from "../components/sectionHeading";
import { Row, Col } from "react-bootstrap";

const Services = () => {
  return (
  <Layout>
    <SEO title="Services: Websites, Copywriting, Marketing" path='services'/>
    
    <Header/>
    
    <ServiceBar />
    
    <hr/>
    <Section id='websites' className='cg-section-shaded' contact>
      <Row>
        <Col>
          <H1>Website Services</H1>
          <div className='cg-servicesSubtitle'>
            Full-service for your website: design, graphics, SEO, hosting, domain names
          </div>
        </Col>
      </Row>
      <Row>
        <Col md>
          <Service title='Hosting & Maintenance' price='$59 / month'>
            This service plan covers website hosting costs, minor updates and one domain name (DNS).
            <br/>
            <Checkout/>
          </Service>
        </Col>
        <Col md>
          <Service title='DNS' price='$39 / year'>
            Many websites have their own address on the internet
            (like <em>example.com</em>). We can help register and manage a name for your site.
          </Service>
        </Col>
      </Row>
      <Row>
        <Col md>
          <Service title='Logo' price='$220 new | $85 revision'>
            Your logo embodies the identity of your organization. We can help you come up with
            a new logo concept or revise an existing logo that needs touching up. This package 
            includes consultation, creation, and industry standard formatting of your logo.
          </Service>
        </Col>
        <Col md>
          <Service title='Coding' price='$85 / hour'>
            Get customized components on your website or find help for other software needs. Our
            team has experience in building websites from scratch. 
          </Service>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>For website design, see below under <a href='#marketing' className='dark'>Marketing</a></p>
        </Col>
      </Row>
    </Section>
    
    
    <hr/>
    <Section id='copywriting' className='cg-section-light' contact>
      <Row>
        <Col>
          <H1>Copywriting Services</H1>
          <div className='cg-servicesSubtitle'>
            The wording on your website should be making you sales. If not, you're missing out!
          </div>
        </Col>
      </Row>
      <Row>
        <Col md>
          <Service title='Sales Letter' price='$420'>
            It's critical for you to communicate to your customers and prospects how 
            much you care. When they buy your product or service, they're trusting 
            your words. We'll help refine your message so it's effective.
          </Service>
        </Col>
        <Col md>
          <Service title='Landing Page' price='$420'>
            Like an online sales letter, the words on a landing page matter. We'll 
            help you get the right words to convey your message.
          </Service>
        </Col>
      </Row>
    </Section>
    
    
    <hr/>
    <Section id='marketing' className='cg-section-shaded' contact>
      <Row>
        <Col>
          <H1>Marketing Services</H1>
          <div className='cg-servicesSubtitle'>
            Make a clear path for customers to follow: from landing page, to email list, to sales offers.
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Service title='Homepage Consult' price='$480'>
            Did you know that the most important part of a website is "above the fold" on 
            the home page? (That's the part a visitor sees before scrolling down.) Many web visitors
            never get past that point and leave before reading the rest of the page.
            When you choose the <em>Homepage Consult</em> service, you'll get
            a report on how to streamline your home page with marketing in mind, with an emphasis on "above the fold."
             After a one-hour interview, you'll get a full report of
            how to optimize your homepage to capture leads. This includes clarifying your message, copywriting, plus 
            image and layout recommendations.
          </Service>
        </Col>
      </Row>
      <Row>
        <Col>
          <Service title='Basic Website' price='$1,200 once + $59 / month'>
            When you choose the <em>Basic Website</em> package you  get the <em>Homepage Consult</em> put
            into action in a new single-page site. This includes everything you need to grab your 
            customer's attention and get more leads. Your new site will be marketing-centered,  
            mobile-friendly, SEO-friendly, and <em>fast</em>.
          </Service>
        </Col>
      </Row>
      <Row>
        <Col lg>
          <Service title='Fullsite Consult' price='$2,550'>
            With the <em>Fullsite Consult</em> report you get everything in the <em>Homepage Consult</em> but
            for five additional pages on your site (or landing pages). As a bonus, you'll also get our guide on how to 
            collect effective reviews and testimonials.
          </Service>
        </Col>
        <Col lg>
          <Service title='Full Website' price='$6,400 once + $59 / month'>
            The <em>Full Website</em> brings everything in the <em>Fullsite Consult</em> report 
            to life in a new website! Your new site will be designed with marketing in mind. Of course, it will 
            also be mobile-friendly, SEO-friendly, and <em>fast</em>.
          </Service>
        </Col>
      </Row>
    </Section>
  </Layout>
)};

export default Services;


function Service(props) {
  return (
    <div className='cg-service'>
      <h2>{props.title}</h2>
      <div className='cg-servicePrice'>{props.price}</div>
      <p>{props.children}</p>
    </div>
      );
}

// See https://www.gatsbyjs.org/tutorial/ecommerce-tutorial/
import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Button } from "react-bootstrap";
import cg from '../cg-config';

// Keep loadStripe outside of render()
// See also layout.js
const stripePromise = loadStripe(cg.stripe.apiKey);

const redirectToCheckout = async event => {
  event.preventDefault()
  const stripe = await stripePromise
  const { error } = await stripe.redirectToCheckout({
    lineItems: [{ price: cg.stripe.priceMaint, quantity: 1 }],
    mode: "subscription",
    successUrl: `${cg.stripe.baseUrl}/checkout-success`,
    cancelUrl: `${cg.stripe.baseUrl}/services`,
  });

  if (error) {
    console.warn("Error:", error);
  }
}

const Checkout = () => {
  return (
    <Button className='cg-dark' onClick={redirectToCheckout}  style={{marginTop:'1rem'}}>
      Get This Plan
    </Button>
  )
}

export default Checkout